import React from 'react';
import Home from '../components/home';
import Layout from '../components/layout';
import SEO from '../components/seo';

const IndexPage = () => (
  <Layout>
    <SEO
      title="Jennifer Mone Hill Artist - Abstract Art - Small Works"
      keywords={[
        'Jennifer Mone Hill',
        'Jennifer Moné Hill',
        'Jen Hill',
        'Jen Hill Artist',
        'painter',
        'artist',
        'photographer',
        'Connecticut',
        'Danbury',
        'CT',
        'New York',
        'NY',
        'NYC',
        'creative talent',
        'small art works'
      ]}
      description="Jennifer Mone Hill modern abstract art. Bold colorful contemporary acrylic paintings and resin paintings. Based in Danbury, Connecticut. Silvermine Artist."
    />
    <Home showSmallWorks={true}/>
  </Layout>
);

export default IndexPage;
